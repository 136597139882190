import React, { ErrorInfo } from 'react';
import SafeAreaLayout from './layouts/SafeAreaLayout';

interface IErrorBoundary {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

const appEnvironment = process.env.REACT_APP_NODE_ENV;
const isProduction = appEnvironment == 'production';

class ErrorBoundary extends React.Component<{}, IErrorBoundary> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <SafeAreaLayout>
          <div className='tw-p-10'>
            <h1 className='tw-text-[red]'>Đã có lỗi xảy ra</h1>
            {!isProduction && (
              <details open className='tw-my-10' style={{ whiteSpace: 'pre-wrap' }}>
                <p className='tw-mb-2 tw-text-[red]'>{this.state.error && this.state.error.toString()}</p>
                {this.state.errorInfo && this.state.errorInfo.componentStack}
              </details>
            )}
          </div>
        </SafeAreaLayout>
      );
    }
    // @ts-ignore
    return this.props.children;
  }
}

export default ErrorBoundary;
