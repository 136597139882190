import HappyMini, { Lib } from '@happymini/core';
import HappyMiniDebugbar from '@happymini/debugbar';
import { AnimationRoutes, ConfigProvider } from '@happymini/react-ui';
import viVN from '@happymini/react-ui/es/locales/vi-VN';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Route, Router } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RecoilRoot } from 'recoil';
import App from './App';
import ErrorBoundary from './ErrorBoundary';
import './index.scss';
import reportWebVitals from './reportWebVitals';


export const browserHistory = createBrowserHistory();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

HappyMini.StatusBar.setBarStyle('light-content');

const appEnvironment = process.env.REACT_APP_NODE_ENV;

if (appEnvironment !== 'production') {
  Lib.use(HappyMiniDebugbar);
}

root.render(
  <ErrorBoundary>
    <Router history={browserHistory}>
      <RecoilRoot>
        <ConfigProvider locale={viVN}>
          <AnimationRoutes>
            <Route component={App} />
          </AnimationRoutes>
        </ConfigProvider>
        <ToastContainer
          position='bottom-center'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='colored'
          style={{ position: 'absolute', bottom: 80, paddingRight: 20, paddingLeft: 20 }}
        />
      </RecoilRoot>
    </Router>
  </ErrorBoundary>
);

reportWebVitals();
