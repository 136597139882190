import { FC, PropsWithChildren } from 'react';
import HappyMini from '@happymini/core';
import { SafeArea } from '@happymini/react-ui';

const statusBarHeight = HappyMini.StatusBar.height;

const SafeAreaLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className='section-container' style={{ paddingTop: statusBarHeight + 16 }}>
      {children}
      <SafeArea position='bottom' />
    </div>
  );
};

export default SafeAreaLayout;
