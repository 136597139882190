import { Routes } from '@topcv/react-router-map';
import { lazy } from 'react';
import { RouteNames } from 'src/constants/routeNames';

const appRoutes: Routes = [
  {
    path: 'name-guessing',
    children: [
      {
        path: 'preload',
        component: lazy(() => import('../pages/preload')),
        name: RouteNames.PRELOAD,
      },
      {
        path: 'play',
        exact: true,
        component: lazy(() => import('../pages/play')),
        name: RouteNames.PLAY,
      },
      {
        path: 'profile',
        component: lazy(() => import('../pages/profile')),
        name: RouteNames.PROFILE,
      },
      {
        path: 'news-feed',
        component: lazy(() => import('../pages/newsfeed')),
        name: RouteNames.NEWSFEED,
      },
      {
        path: 'error',
        component: lazy(() => import('../pages/error')),
        name: RouteNames.ERROR,
      },
      {
        path: '',
        redirectTo: 'preload',
      },
    ],
  },
  {
    path: '',
    redirectTo: 'name-guessing',
  },
];

export default appRoutes;
